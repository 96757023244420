// utils/firebase.js
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import "firebase/compat/firestore"

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyAZa1FUEJrAO0j8ZL8ft-6FwNTh4ob-4hg', // required
    authDomain: 'pinto-financial.firebaseapp.com',
    databaseURL: 'https://pinto-financial.firebaseio.com',
    projectId: 'pinto-financial',
  })
}

export default firebase
