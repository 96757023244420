import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "../utils/firebase";
import { getUser } from "../utils/firestore";

const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [firestoreData, setFirestoreData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for firebase auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // Set basic user data from firebase auth
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });

        // Fetch additional user data from firestore
        try {
          const userData = await getUser(user.uid);
          console.log('user data', userData) 
          setFirestoreData(userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setUser(null);
        setFirestoreData(null);
      }
      setLoading(false);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  // Combine auth and firestore data
  const userData = user ? {
    ...user,
    ...firestoreData
  } : null;

  return (
    <UserContext.Provider value={{ user: userData, loading }}>
      {!loading && children}
    </UserContext.Provider>
  );
};
