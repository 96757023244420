import firebase from "../utils/firebase";
const db = firebase.firestore();

export const firestore = db;

export const getNotesByCompanyId = async (companyId) => {
  try {
    const notesRef = db.collection("notes");
    const snapshot = await notesRef.where("companyId", "==", companyId).get();
    if (snapshot.empty) {
      console.log("No matching notes found.");
      return [];
    }
    let notes = [];
    snapshot.forEach((doc) => {
      notes.push({ id: doc.id, ...doc.data() });
    });
    return notes;
  } catch (error) {
    console.error("Error getting notes by company ID: ", error);
    throw error;
  }
};

export const updateNote = async (noteId, noteDetails) => {
  try {
    const noteRef = db.collection("notes").doc(noteId);
    await noteRef.update({
      ...noteDetails,
      updatedAt: Date.now(),
    });
    return noteId;
  } catch (error) {
    console.error("Error updating note: ", error);
    throw error;
  }
};

export const addNote = async (noteDetails) => {
  try {
    const noteRef = db.collection("notes");
    const newNote = await noteRef.add({
      ...noteDetails,
      createdAt: Date.now(),
    });
    return newNote.id;
  } catch (error) {
    console.error("Error adding note: ", error);
    throw error;
  }
};

export const addUser = async (user, business, name) => {
  try {
    let newUser = await db.collection("users").doc(user.uid);
    await newUser.set(
      {
        email: user.email,
        name: name,
      },
      { merge: true }
    );

    let newCompany = await db.collection("companies").add({
      name: business,
      adminAuthId: user.uid,
      createdAt: Date.now(),
    });

    let newUserCompany = db.collection("usersCompanies").doc(user.uid);
    await newUserCompany.set(
      {
        companies: [newCompany.id],
      },
      { merge: true }
    );

    let setWithMerge = await newUser.set(
      {
        companyId: newCompany.id,
      },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const addInvitedUser = async (user, name) => {
  try {
    let newUser = await db.collection("users").doc(user.uid);
    await newUser.set(
      {
        email: user.email,
        name: name,
      },
      { merge: true }
    );

    let newUserCompany = db.collection("usersCompanies").doc(user.uid);
    await newUserCompany.set(
      {
        companies: [],
      },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const getUser = async (userId) => {
  try {
    let userRef = db.collection("users").doc(userId);
    let userData = await userRef.get();
    return userData.data();
  } catch (error) {
    console.error("error getting user: ", error);
    return;
  }
};

export const updateCompany = async (coId, data) => {
  try {
    let companyRef = await db.collection("companies").doc(coId);
    let setWithMerge = await companyRef.set({ ...data }, { merge: true });
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const upsertCompanyToken = async (token, realmId, coId) => {
  try {
    let companyRef = await db.collection("companies").doc(coId);
    let setWithMerge = companyRef.set(
      { token, realmId: realmId },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const updateCompanyDetailsById = async (coId, data) => {
  try {
    let companyRef = db.collection("company-details").doc(coId);
    data.CompanyId = coId;
    data.CreatedAt = Date.now();
    let setWithMerge = companyRef.set(data, { merge: true });
    return;
  } catch (error) {
    console.error("error updating company details: ", error);
    throw "error";
  }
};

export const updateCompanyPreferencesById = async (coId, data) => {
  try {
    let companyRef = db.collection("company-preferences").doc(coId);
    data.CompanyId = coId;
    data.CreatedAt = Date.now();
    let setWithMerge = companyRef.set(data, { merge: true });
    return;
  } catch (error) {
    console.error("error updating company preferences: ", error);
    throw "error";
  }
};

export const getAllCompanies = async () => {
  try {
    let companiesRef = db.collection("companies");
    let companiesData = await companiesRef.get();
    const tempCo = companiesData.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return tempCo;
  } catch (error) {
    console.error("Could Not Get All Companies: ", error);
    return;
  }
};

export const getCompanyById = async (coId) => {
  try {
    let companyRef = db.collection("companies").doc(coId);
    //console.log("companyRef", companyRef);
    let companyData = await companyRef.get();
    //console.log(companyData, "companyData");
    //console.log("companyData.data", companyData.data());
    return { ...companyData.data(), id: coId };
  } catch (error) {
    console.error("Could Not Find Company: ", error);
    return;
  }
};

export const createReport = async (name, coId, reportSettings, hasBudget) => {
  try {
    if (!name || !coId || !reportSettings) {
      throw "missing data";
    }
    let newReport = await db.collection("reports").add({
      name: name,
      companyId: coId,
      createdAt: Date.now(),
      visible: true,
      settings: reportSettings,
      hasBudget: hasBudget,
    });
    return newReport;
  } catch (error) {
    console.error("Error adding sales by products record: ", error);
    return;
  }
};

export const getReports = async (coId) => {
  try {
    const reportsRef = db.collection("reports");
    const reports = await reportsRef.where("companyId", "==", coId).get();
    //console.log("Reports", reports);
    let reportsData = [];
    reports.forEach((doc) => {
      if (doc.data().visible) {
        reportsData.push({
          id: doc.id,
          ...doc.data(),
        });
      }
      //console.log(doc.id, "=>", doc.data());
    });

    // order reportsData by createdAt
    reportsData.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
    return reportsData;
  } catch (error) {
    console.error("Error getting reports: ", error);
    return;
  }
};

export const getAllReports = async () => {
  try {
    const reportsRef = db.collection("reports");
    const reports = await reportsRef.get();
    let reportsData = [];
    reports.forEach((doc) => {
      reportsData.push({
        id: doc.id,
        ...doc.data(),
      });
      // console.log(doc.id, '=>', doc.data());
    });
    return reportsData;
  } catch (error) {
    console.error("Error getting reports: ", error);
    return;
  }
};

export const getProposal = async (id) => {
  try {
    let pRef = db.collection("proposals").doc(id);
    let pData = await pRef.get();
    return pData.data();
  } catch (error) {
    console.error("error getting proposal: ", error);
    return;
  }
};

export const getUserByEmail = async (userEmail) => {
  try {
    let userRef = db.collection("users");
    let userData = (await userRef.where("email", "==", userEmail).get())
      .docs[0];
    return { ...userData.data(), id: userData.id };
  } catch (error) {
    console.error("error getting user: ", error);
    return;
  }
};

export const getCompanyMembers = async (coId) => {
  try {
    let usersCompaniesRef = db.collection("usersCompanies");
    const usersCompaniesSnapshot = await usersCompaniesRef
      .where("companies", "array-contains", coId)
      .get();
    const companyDetails = await getCompanyById(coId);

    const usersPromises = [];

    for (const doc of usersCompaniesSnapshot.docs) {
      const user = await getUser(doc.id);
      const userRole =
        doc.id === companyDetails?.adminAuthId ? "owner" : "member";
      usersPromises.push({ ...user, id: doc.id, role: userRole });
    }

    const users = await Promise.all(usersPromises);
    return users;
  } catch (error) {
    console.error("Error getting members: ", error);
    return;
  }
};

export const getPendingInvitation = async (coId) => {
  try {
    let usersInvitationsRef = db.collection("usersInvitations");
    const usersInvitationsSnapshot = await usersInvitationsRef
      .where("companyId", "==", coId)
      .get();

    if (usersInvitationsSnapshot.empty) {
      return [];
    }

    const invitationDocs = usersInvitationsSnapshot.docs;
    const invitations = invitationDocs.map((doc) => doc.data());

    return invitations;
  } catch (error) {
    console.error("Error getting pending invitations: ", error);
    return;
  }
};

export const getUserCompanies = async (user) => {
  try {
    if (user.email.endsWith("@pintofinancial.com")) {
      let companiesRef = db.collection("companies");
      let companiesData = await companiesRef.get();
      const tempCo = companiesData.docs.map((doc) => {
        return getCompanyById(doc.id);
      });
      const allCos = await Promise.all(tempCo);
      return allCos.map((co) => {
        return { id: co.id, name: co.name };
      });
    } else {
      let userCompaniesRef = db.collection("usersCompanies").doc(user.id);
      const userCompaniesSnapshot = await userCompaniesRef.get();
      const allCompanyData = [];
      for (const company of userCompaniesSnapshot.data().companies) {
        const companyData = await getCompanyById(company);
        allCompanyData.push({
          name: companyData.name,
          id: companyData.id,
        });
      }
      return allCompanyData;
    }
  } catch (error) {
    console.error("Error getting companies: ", error);
    return;
  }
};

export const inviteTeamMember = async (memberEmail, coId) => {
  try {
    let userRef = db.collection("users");
    let userData = await userRef.where("email", "==", memberEmail).get();
    let userInvitationsRef = db.collection("usersInvitations");

    // Non-registered users
    if (userData.empty) {
      // Check if the user is already invited to the specific company
      const existingInvitation = await userInvitationsRef
        .where("email", "==", memberEmail)
        .where("companyId", "==", coId)
        .get();

      if (existingInvitation.empty) {
        await userInvitationsRef.add({
          email: memberEmail,
          companyId: coId,
        });
      } else {
        throw new Error("User is already invited to this company.");
      }
      return;
    }

    let userId = userData.docs[0].id;

    let userCompaniesRef = db.collection("usersCompanies").doc(userId);
    let userCompaniesSnapshot = await userCompaniesRef.get();

    // Registered users
    if (!userCompaniesSnapshot.exists) {
      await userCompaniesRef.set({
        companies: [coId],
      });
    } else {
      // Check if the company is already added before updating
      let userCompaniesData = userCompaniesSnapshot.data();
      if (!userCompaniesData.companies.includes(coId)) {
        await userCompaniesRef.update({
          companies: [...userCompaniesData.companies, coId],
        });
      } else {
        throw new Error("User is already added to this company");
      }
    }

    return userId;
  } catch (error) {
    console.error("Error inviting member: ", error);
    throw error;
  }
};

export const removeMember = async (memberId, coId) => {
  try {
    let userCompaniesRef = db.collection("usersCompanies").doc(memberId);
    let userCompaniesSnapshot = await userCompaniesRef.get();

    await userCompaniesRef.set({
      companies: userCompaniesSnapshot
        .data()
        .companies.filter((i) => i !== coId),
    });

    return;
  } catch (error) {
    console.error("Error remove member: ", error);
    return;
  }
};

export const checkInvitations = async (email) => {
  try {
    let userInvitationsRef = db.collection("usersInvitations");
    let userInvitationsSnapshot = await userInvitationsRef
      .where("email", "==", email)
      .get();
    if (!userInvitationsSnapshot.empty) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
export const checkInvitationsAndUpdate = async (email, user) => {
  try {
    let userInvitationsRef = db.collection("usersInvitations");
    let userInvitationsSnapshot = await userInvitationsRef
      .where("email", "==", email)
      .get();
    let userCompaniesRef = db.collection("usersCompanies").doc(user.uid);
    let userCompaniesDoc = await userCompaniesRef.get();
    let userCompaniesData = userCompaniesDoc.data();

    if (!userInvitationsSnapshot.empty) {
      const companyIdsToAdd = userInvitationsSnapshot.docs.map(
        (invitationDoc) => invitationDoc.data().companyId
      );
      await userCompaniesRef.update({
        companies: [...userCompaniesData?.companies, ...companyIdsToAdd],
      });

      const invitationIdsToRemove = userInvitationsSnapshot.docs.map(
        (invitationDoc) => invitationDoc.id
      );

      for (const invitationId of invitationIdsToRemove) {
        await userInvitationsRef.doc(invitationId).delete();
      }
    }

    return;
  } catch (error) {
    console.error("Error checking invitations: ", error);
    throw "Error No Invitation";
  }
};

export const transferOwnership = async (memberId, coId) => {
  try {
    let userCompaniesRef = db.collection("companies").doc(coId);

    await userCompaniesRef.update({
      adminAuthId: memberId,
    });

    return;
  } catch (error) {
    console.error("Error transferring ownership: ", error);
  }
};
