import React, { createContext, useContext, useState } from "react";

const CompanyContext = createContext();

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [userRole, setUserRole] = useState(null);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany, userRole, setUserRole }}>
      {children}
    </CompanyContext.Provider>
  );
};
